// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import { start } from '@rails/activestorage/src';

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');

require('jquery/dist/jquery');
require('jquery-ui-dist/jquery-ui');
require('bootstrap/dist/js/bootstrap');
require('highcharts/highcharts');
//require("moment/dist/moment");
require('popper.js/dist/popper');
//require("tippy.js/dist/tippy.esm");
//require("toastr/toastr");
require('@fortawesome/fontawesome-free/js/all');
require('@nathanvda/cocoon');
require('boxicons/dist/boxicons');
require('bootstrap5-toggle/js/bootstrap5-toggle');
//require("signature_pad/dist/signature_pad");

// Custom JS Goes Under Here
import { SessionManager } from './sessiontimeout';
global.SessionManager = SessionManager;

import { viewableCalendar } from './viewCalendar';
global.viewableCalendar = viewableCalendar;

import { editableCalendar } from './editCalendar';
global.editableCalendar = editableCalendar;

import jquery from 'jquery/dist/jquery';
global.$ = jquery;
global.jquery = jquery;
global.JQuery = jquery;
global.jQuery - jquery;

import toastr from 'toastr/toastr';
global.toastr = toastr;

import moment from 'moment/dist/moment';
global.moment = moment;

import tippy from 'tippy.js/dist/tippy.esm';
global.tippy = tippy;

import { Filterrific } from './filterrific-jquery';
global.Filterrific = Filterrific;

import SignaturePad from 'signature_pad/dist/signature_pad';
global.SignaturePad = SignaturePad;

import Highcharts from 'highcharts';
import HighchartsDrilldown from 'highcharts/modules/drilldown';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import HighchartsGantt from 'highcharts/modules/gantt';
import GanttChart from 'highcharts/highcharts-gantt'
HighchartsDrilldown(Highcharts);
HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);
HighchartsExporting(Highcharts);
HighchartsNoData(Highcharts);
HighchartsAccessibility(Highcharts);
HighchartsGantt(Highcharts);
global.Highcharts = Highcharts;
global.HighchartsGantt = GanttChart;

import { Calendar } from '@fullcalendar/core';
global.Calendar = Calendar;
import interactionPlugin from '@fullcalendar/interaction';
global.interactionPlugin = interactionPlugin;
import dayGridPlugin from '@fullcalendar/daygrid';
global.dayGridPlugin = dayGridPlugin;
import timeGridPlugin from '@fullcalendar/timegrid';
global.timeGridPlugin = timeGridPlugin;
import listPlugin from '@fullcalendar/list';
global.listPlugin = listPlugin;
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
global.bootstrap5Plugin = bootstrap5Plugin;
import momentPlugin from '@fullcalendar/moment';
global.momentPlugin = momentPlugin;

// Chart functions
import { setChartSeries } from './chart_functions';
global.setChartSeries = setChartSeries;
import { setChartDrilldown } from './chart_functions';
global.setChartDrilldown = setChartDrilldown;
import { refreshChart } from './chart_functions';
global.refreshChart = refreshChart;

// var signaturePad = new SignaturePad(document.getElementById('signature-pad'), {
//   backgroundColor: 'rgba(255, 255, 255, 0)',
//   penColor: 'rgb(0, 0, 0)'
// });

// Let's import all the stylesheets now
import('../stylesheets/application.scss');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true);
const imagePath = (name) => images(name, true);
